// Base theme for Warframe: 1999 doktorentrati.com ARG page

@import "../style-guide/_variables";
@import "../style-guide/_responsive";
@import "../style-guide/_mixins";
@import "../style-guide/_typography";

body {
    background: $COLOR_WHITE;
    color: $COLOR_TEXT;
    margin: 0;
    padding: 0;
    font-family: VT323, "Arial", sans-serif;
}

@keyframes OverlayGlitchOut {
    0% {
        mask-image: linear-gradient(
                to bottom,
                black 20%,
                transparent 21%,
                transparent 39%,
                black 40%,
                black 75%,
                transparent 76%
        );
        opacity: 1;
        transform: scale(1);
    }
    20% {
        mask-image: linear-gradient(
                to bottom,
                transparent 29%,
                black 30%,
                black 46%,
                transparent 47%,
                transparent 65%,
                black 66%
        );
        opacity: 1;
        transform: scale(1);
    }
    40% {
        mask-image: linear-gradient(
                to bottom,
                black 9%,
                transparent 10%,
                transparent 26%,
                black 27%,
                black 48%,
                transparent 49%
        );
        opacity: 1;
        transform: scale(1);
    }
    60% {
        mask-image: linear-gradient(
                to bottom,
                transparent 17%,
                black 18%,
                black 51%,
                transparent 52%,
                transparent 79%,
                black 80%
        );
        opacity: 1;
        transform: scale(1.1);
        filter: invert(1);
    }
    80% {
        mask-image: linear-gradient(
                to bottom,
                black 33%,
                transparent 34%,
                transparent 44%,
                black 45%,
                black 51%,
                transparent 52%
        );
        opacity: 1;
        transform: scale(1.05);
    }
    100% {
        mask-image: linear-gradient(
                to bottom,
                transparent,
                transparent
        );
        opacity: 0;
    }
}
#doktorentrati {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: $COLOR_WHITE;
    color: $COLOR_TEXT;

    opacity: 1;

    &.is-disabled {
        animation: OverlayGlitchOut 500ms steps(2, end) both 1;
        pointer-events: none;
    }
}

.EntratiEye {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    aspect-ratio: 1;
    min-width: 256px;
    background: $COLOR_WHITE;

    @include breakpoint-tablet-up {
        width: 70%;
    }
    @include breakpoint-desktop-up {
        width: 30%;
    }

    &-base {
        position: relative;
        display: block;
        width: 100%;
        aspect-ratio: 1;
        object-fit: contain;
    }
    &-iris {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        aspect-ratio: 1;
        object-fit: contain;
        transform: translate(-50%, -55%);
    }
}

.EntratiContent {
    position: absolute;
    top: 65%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 101;
    text-align: center;
    gap: 35px;


    @include breakpoint-mobile-only{
        top: 55%;
    }
}

.text {
    font-size: 4rem;
    font-family: VT323, "Arial", sans-serif;
    line-height: 50px;

    @include breakpoint-mobile-only{
        font-size: 3rem;
    }
}

.btn,
.overlay {
    position: relative;
    width: 250px;
    height: 70px;
    font-size: 36px;
    font-family: VT323, "Arial", sans-serif;
    background: black;
    color: #fff;
    letter-spacing: 3px;
    line-height: 70px;
    box-shadow: 6px 0 0 #fff;
    outline: transparent;
    text-decoration: none;
}
.buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 8px;
}
.btn {
    border: 4px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;

    &:visited {
        color: #fff;
    }
    &:hover {
        cursor: pointer;
        color: black;
        background-color: white;
        border: 4px solid black;
        text-decoration: none;
    }

    &.disabled {
        border-color: #333;
        background-color: #666;
        color: #333;

        &:disabled {
            color: #333;
        }
        &:hover {
            cursor: default;
            color: #333;
            background-color: #666;
            border-color: #333;
        }
    }
}
.overlay {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #000 3%, #000 5%);
    text-shadow: -3px -3px 0 #fff, 3px 3px 0 #000; /* Black text shadow */
    clip-path: var(--slice-0);
    animation: glitch 4s steps(2, end) both infinite;
}

@keyframes glitch {
    0% {
        clip-path: none;
        transform: translate(0);
        opacity: 0;
    }
    79% {
        clip-path: none;
        transform: translate(0);
        opacity: 0;
    }
    80% {
        clip-path: var(--slice-1);
        transform: translate(-20px, -10px);
        opacity: 1;
    }
    82% {
        clip-path: var(--slice-3);
        transform: translate(10px, 10px);
    }
    84% {
        clip-path: var(--slice-1);
        transform: translate(-10px, 10px);
    }
    86% {
        clip-path: var(--slice-3);
        transform: translate(0px, 5px);
    }
    88% {
        clip-path: var(--slice-2);
        transform: translate(-5px, 0px);
    }
    90% {
        clip-path: var(--slice-3);
        transform: translate(5px, 0px);
    }
    92% {
        clip-path: var(--slice-4);
        transform: translate(5px, 10px);
    }
    94% {
        clip-path: var(--slice-2);
        transform: translate(-10px, 10px);
    }
    96% {
        clip-path: var(--slice-5);
        transform: translate(20px, -10px);
    }
    98% {
        clip-path: var(--slice-1);
        transform: translate(-10px, 0px);
        opacity: 1;
    }
    100% {
        clip-path: none;
        transform: translate(0);
        opacity: 0
    }
}
